import './App.css';
import { BlogIcon, DiscordIcon, InstagramIcon, RedditIcon, TelegramIcon, TwitterIcon, WebsiteIcon } from './SvgIcons';

function Link(props) {

  return (
    <a href={props.href}
      className="buttonClass mb-4 min-h-[56px] w-full flex text-center justify-center 
                  items-center font-semibold transition ease-out duration-150 
                  link-hover-default link-hover-defaultBg whitespace-pre-wrap 
                  word-wrap-anywhere">
      {props.children}
    </a>
  )
}

function Title(props) {
  return (
    <p className="text-gray-400 text-4xl font-thin border-b-orange-400 border-b-2">
      {props.children}
    </p>
  )
}

function Links(props) {

  return (
    <div className="p-3 App-header flex flex-col w-full min-w-0 max-w-md gap-y-4">

      {/* --------------------------------------------------------------- */}
      <Title>
        Official Game Links
      </Title>

      <Link href="https://apps.apple.com/au/app/shiba-eternity/id1633003122">
        Apple App Store
      </Link>

      <Link href="https://play.google.com/store/apps/details?id=com.shibagames.shibawoof">
        Google Play
      </Link>

      <Link href="https://www.playsidestudios.com/post/playside-x-shiba-inu"
        class="buttonClass">
        Playside x Shiba Inu
      </Link>

      {/* --------------------------------------------------------------- */}
      <Title>
        Official Websites
      </Title>

      <Link href="https://blog.shibaswap.com/">Shiba Token - Official Blog</Link>
      <Link href="https://shibatoken.com">Shiba Token - Official Website</Link>
      <Link href="https://shib.io">SHIB - The Metaverse</Link>

      {/* --------------------------------------------------------------- */}
      <Title>
        Twitter Profiles
      </Title>

      <Link href="https://twitter.com/shibaeternity">
        Shiba Eternity™
      </Link>

      <Link href="https://twitter.com/ShytoshiKusama">
        Shytoshi Kusama™
      </Link>

      <Link href="https://twitter.com/PlaysideStudios">
        Playside Studios
      </Link>

      <Link href="https://twitter.com/william_volk">
        William Volk
      </Link>

      <Link href="https://twitter.com/marciejastrow">
        Marcie Jastrow
      </Link>

      <Link href="https://twitter.com/ShibTheMV">
        SHIB The Metaverse
      </Link>
      
      <Link href="https://twitter.com/SHIBQueenie">SHIBQueenie</Link>
      <Link href="https://twitter.com/metastageXR">Metastage</Link>
      <Link href="https://twitter.com/ShibaHispano">Shiba Inu Official (ES)</Link>
      <Link href="https://twitter.com/ShibaTelevision">Shiba Television</Link>

    </div>
  )
}

function Icon(props) {
  return (
    <div className="basis-1/6 flex-none flex justify-center items-center p-2 pl-6 pr-6">
      <a href={props.href}>
        {props.children}
      </a>
    </div>
  )
}

function SocialMedia(props) {

  return (
    <div className="flex flex-wrap sm:flex-nowrap max-w-sm items-center justify-center pt-6">
      <Icon href="https://discord.com/invite/shibatoken" src="/imgs/discord.svg"><DiscordIcon /></Icon>
      <Icon href="https://blog.shibaswap.com/" src="/imgs/blog.svg"><BlogIcon /></Icon>
      <Icon href="https://www.instagram.com/shibatoken_official/" src="/imgs/instagram.svg"><InstagramIcon /></Icon>
      <Icon href="https://www.reddit.com/r/SHIBArmy/" src="/imgs/reddit.svg"><RedditIcon /></Icon>
      <Icon href="https://t.me/ShibaInu_Dogecoinkiller" src="/imgs/telegram.svg"><TelegramIcon /></Icon>
      <Icon href="https://twitter.com/Shibtoken" src="/imgs/twitter.svg"><TwitterIcon /></Icon>
      <Icon href="https://shibatoken.com" src="/imgs/website.svg"><WebsiteIcon /></Icon>

    </div>
  )
}

function Cards(props) {

  return (
    <div className="hidden mr-10 ml-10 md:grid w-32 gap-y-14 gap-x-14">
      {Array(7).fill().map((_, idx) => {
        return (
          <img className="img-drop drop-shadow-md shadow-slate-300 rounded-2xl" src={`/media/cards/${idx + +props.start + 1}.png`}  alt="logo" />
        )
      })}
    </div>
  )
}

function Video(props) {
  return (
    <video width="800" className="img-drop m-10" controls>
      <source src={props.src} type="video/mp4" />
      Your browser does not support the video tag.
    </video> 
  )
}



function App() {
  return (
    <div className="">
      <div className="App flex flex-col flex-grow justify-center bg-slate-800">
        <header className="flex-grow p-3 App-header flex flex-col w-full mb-10 min-w-0 gap-y-4">

          <img src='/imgs/shiba-inu.png' className="img-drop shadow-slate-300 rounded-3xl w-72 drop-shadow-lg" alt="logo" />

          <p className="mb-5 text-4xl">
            Welcome to the <span className='text-orange-400'>Unofficial</span> Shiba Eternity Fan Page
          </p>
          <SocialMedia />
        </header>
      </div>
      <div className="App flex flex-row justify-center pb-10 bg-slate-800">
        <Cards start="0"/>
        <Links />
        <Cards start="7"/>
      </div>
      <div className="flex flex-col items-center justify-center bg-slate-800 pt-8">
        {/* --------------------------------------------------------------- */}
        <Title>
          Media
        </Title>

        <Video src="media/vid3.mp4"/>
        <Video src="media/vid2.mp4"/>
        <Video src="media/vid1.mp4"/>
      </div>
      <div className="App-header bg-slate-900 flex flex-col sm:p-32">
        <SocialMedia />
        <p className="text-lg text-center font-light mt-10 text-amber-800 p-4">
          <span className='text-orange-700'>Notice regarding this website: </span>
          This website has been created by unofficial Shiba Eternity fans in order to make it more 
            convenient for the public to quickly find information.
          This website is not affiliated with PlaySide™ or Shiba Inu Ecosystem ©.
          This website is not endorsed or otherwise sponsored by any official source including 
            PlaySide™ or Shiba Inu Ecosystem ©.
          Portions of the materials contained herein are property of PlaySide™ or Shiba Inu Ecosystem ©.
        </p>
        <p className='text-lg text-center font-light mt-10 text-amber-800 p-4 pb-10'>
          Please let us know what you think! {' '} <br/>
          💕
          <a className='cursor-pointer underline' href="https://signal.group/#CjQKIDRnNeVcj7Cz2pffDSbB4CsFfFK6XNQ3X_K5lOsfN6deEhAfLzZ3SbS8puu57DN2sCTP">Signal Group</a>
          💕
        </p>
      </div>
    </div>
  );
}

export default App;
